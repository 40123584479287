/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import ScheduleItem from '@atoms/ScheduleItem';
import { useWindowDimensions } from '@common/application/hooks/useWindowDimensions';
import styled from '@emotion/styled';
import React, { Dispatch, SetStateAction } from 'react';

const StyledDocumentSummary = styled.div<DocSummProps>`
  display: flex;
  width: 100%;
  //width: ${({ shrink }) => (shrink ? 'fit-content' : '100%')};
  background: #eeeeee;
  //background: ${cssVars.lightGrey}5f;
  //padding: 4px;
  border-radius: 4px;
  //margin-inline: auto;
  justify-content: space-around;
  align-items: center;
  min-height: 100%;
  user-select: none;
  position: relative;
  z-index: 9;

  @media (max-width: 1024px) {
    background: ${(props) => (props.dark ? cssVars.darkGrey : '#eeeeee')};
  }
`;

interface DocSummProps {
  summary: Array<{ documentStatus: DocumentStatus; count: number }>;
  filter?: DocumentStatus;
  setFilter?: Dispatch<SetStateAction<DocumentStatus>>;
  shrink?: boolean;
  dark?: boolean;
}

// I know having these two overlapping enums is dumb but as you currently can't extend an enum in TS this seemed like the best/easiest solution
// Having them all under one enum seems weird as although they are linked they are also semantically different
export enum DocumentStatus {
  ALL = 'total',
  NONE = 'orphaned',
  CREATED = 'created',
  PROVIDED = 'provided',
  ACCEPTED = 'accepted',
}

export enum ScheduleTypeId {
  ACCEPTED = 'accepted',
  CREATED = 'created',
  PROVIDED = 'provided',
  OTHER = 'other',
}

function DocumentSummary(props: DocSummProps) {
  const { width } = useWindowDimensions();
  const containsUnknownStatus = !!Number(props.summary?.find((s) => s.documentStatus === DocumentStatus.NONE)?.count);
  const categories = containsUnknownStatus ? Object.keys(DocumentStatus) : Object.keys(DocumentStatus).filter((s) => DocumentStatus[s] !== DocumentStatus.NONE);
  return (
    <StyledDocumentSummary shrink={width < 1024} {...props}>
      {categories.map((t) => (
        <ScheduleItem
          key={`summary-${t}`}
          typeId={DocumentStatus[t]}
          count={props.summary?.find((s) => s.documentStatus === DocumentStatus[t])?.count ?? 0}
          filter={props.filter}
          setFilter={props.setFilter}
          dark={props.dark}
        />
      ))}
    </StyledDocumentSummary>
  );
}

export default DocumentSummary;
